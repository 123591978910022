import schttp from 'public/src/services/schttp'

export const footFetch = async ({ dataType, channelId }) => {
  try {
    const res = await schttp({
      url: '/getSheinInfo',
      params: {
        sceneKey: dataType,
        cccChannelId: channelId,
      },
    })
    
    if (res?.code == 1000 && res?.data?.content?.content?.props) {
      const items = res.data.content.content.props['items']
      if (items.length) {
        return items[0] || {}
      }
    }
    return {}
  } catch(e) {
    console.error(e)
    return {}
  }
}
